import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { CuiButton, CuiSpinner } from 'front-lib'

import { mapMealsForMembershipRefetch } from 'src/modules/MyPlan/components/OrderSummaryV2/utils'
import { ReactComponent as Alert } from 'src/assets/svg/alert-circle.svg'
import { getOrderWithStock } from 'src/redux-api-bridge/order/selectors'
import { useStartMembership } from 'src/api/Membership/useStartMembership'
import { useRejoinMembership } from 'src/api/Membership/useRejoinMembership'
import { useTrackMembershipModal } from '../../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { membershipModalAtom } from 'src/atoms/modals/membershipModal.atom'
import {
  MembershipData,
  MembershipOverlayActions,
  MembershipOverlayName,
  Step
} from 'src/types/membership'
import UnityPassImage from 'src/assets/img/unitypass.png'
import './UnityPassCheckout.scss'

import { fetchOrderDetail } from 'src/redux-api-bridge/order/effects'
import { getDiscountInfo } from '../utils'

/**
 * Constants
 */

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL

/**
 * Types
 */

interface Props {
  onContinue: (step: Step) => void
  membershipData: MembershipData
}

/**
 * UnityPassCheckout component
 */

export function UnityPassCheckout({ membershipData, onContinue }: Props) {
  const [errorMessage, setErrorMessage] = useState('')
  const { isRejoin } = useAtomValue(membershipModalAtom)
  const { startMembership, loading: startLoading } = useStartMembership({
    onCompleted,
    onError
  })
  const { rejoinMembership, loading: rejoinLoading } = useRejoinMembership({
    onCompleted,
    onError
  })
  const location = useLocation()
  const dispatch = useDispatch()
  const orderWithStock = useSelector(getOrderWithStock)
  const origin = isOrderSummaryPath()
    ? 'Order checkout upselling banner'
    : 'User menu'
  const { trackOptionClicked, trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.checkout,
    origin
  })

  const { membership } = membershipData

  const { discountValue, discountRate, finalCost, fullCost } = getDiscountInfo(
    membershipData?.membership
  )

  function isOrderSummaryPath() {
    const currentPath = location.pathname
    return currentPath.includes('/meals')
  }

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onPurchaseMembership() {
    if (membership?.id) {
      setErrorMessage('')
      if (isRejoin) {
        rejoinMembership({ membershipId: membership.id })
      } else {
        startMembership({ membershipId: membership.id })
      }
      trackOptionClicked({ overlayAction: MembershipOverlayActions.confirm })
    }
  }

  function onCompleted(response) {
    if (
      response.startMembership?.success ||
      response.rejoinMembership?.success
    ) {
      if (isOrderSummaryPath()) {
        const meals = mapMealsForMembershipRefetch(orderWithStock)
        dispatch(fetchOrderDetail(meals))
      }
      onContinue(MEMBERSHIP_MODAL_STEPS.confirmation)
    } else {
      onError(response)
    }
  }

  function onError(response) {
    const error =
      response?.startMembership?.error || response?.rejoinMembership?.error
    const isUnhandledError = response?.graphQLErrors?.length > 0 || !error
    const errorMessage = isUnhandledError
      ? 'Sorry, we encountered an error. Please try again.'
      : error
    setErrorMessage(errorMessage)
  }

  if (!membership) {
    return null
  }

  return (
    <>
      <div className="unity-pass-banner-by-chef">
        <img src={UnityPassImage} alt="Unity Pass" />
        <div className="unity-pass-by-chef">
          {`a special invitation from Chef ${membership?.discount?.source}`}
        </div>
      </div>
      <div className="checkout-container">
        {(startLoading || rejoinLoading) && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <span className="title">Please review your Unity Pass purchase</span>
        <div className="summary-container">
          <span className="title">Order Summary</span>
          <div className="detail">
            <span>Unity Pass</span>
            <span>${fullCost}</span>
          </div>
          <div className="summary-description">
            <span className="first">
              Benefits: <strong>free delivery</strong>, exclusive discounts,
              three premium meals per order at no extra fee.
            </span>
            <span className="second">
              This is a recurring monthly charge — cancel anytime.
            </span>
          </div>
          <span className="chef-discount">
            <span>
              {`${discountRate}% Off (${membership?.discount?.source}) Discount`}
            </span>
            <span>-${discountValue}</span>
          </span>
          <hr className="dotted-line"></hr>
          <div className="summary-footer">
            <span>ORDER TOTAL</span>
            <span>${finalCost}</span>
          </div>
        </div>
        {!errorMessage && (
          <div className="terms">
            <span>
              By tapping the button, I agree to the{' '}
              <a target="_blank" href={`${BASE_URL}/terms`} rel="noreferrer">
                Terms and Conditions
              </a>{' '}
              and a recurring monthly charge of ${finalCost} plus tax until
              canceled. To avoid charges, cancel in your account before renewal.
            </span>
          </div>
        )}
        {errorMessage && (
          <div className="error">
            <div className="image">
              <Alert className="alert-error" />
            </div>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className="unity-pass-buttons-container">
          <CuiButton onClick={onPurchaseMembership}>Confirm purchase</CuiButton>
        </div>
      </div>
    </>
  )
}
