import { createAction } from 'redux-actions'

export const getUserMenuStart = createAction('GET_USER_MENU_START')
export const getUserMenuSuccess = createAction('GET_USER_MENU_SUCCEED')
export const getUserMenuFail = createAction('GET_USER_MENU_FAILED')

export const getUserAlreadyRequestedMealsStart = createAction(
  'GET_USER_ALREADY_REQUESTED_MEALS_START'
)
export const getUserAlreadyRequestedMealsSuccess = createAction(
  'GET_USER_ALREADY_REQUESTED_MEALS_SUCCEED'
)
export const getUserAlreadyRequestedMealsFail = createAction(
  'GET_USER_ALREADY_REQUESTED_MEALS_FAILED'
)

export const resetUserMenu = createAction('RESET_USER_MENU')
export const setIsCrossSellingModalOpen = createAction(
  'SET_IS_CROSS_SELLING_MODAL_OPEN'
)
export const setInitialScrollTo = createAction('SET_MENU_INITIAL_SCROLL_TO')
export const setMenuViewMode = createAction('SET_MENU_VIEW_MODE')

export const getCrossSellingItemsStart = createAction('GET_CROSS_SELLING_START')
export const getCrossSellingItemsSuccess = createAction(
  'GET_CROSS_SELLING_SUCCEED'
)
export const getCrossSellingItemsFail = createAction('GET_CROSS_SELLING_FAILED')

export const setCrossSellingModalSeen = createAction(
  'SET_CROSS_SELLING_MODAL_SEEN'
)

export const setFullMenuMealInitialPosition = createAction(
  'SET_FULL_MENU_MEAL_INITIAL_POSITION'
)
