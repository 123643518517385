import {
  FEATURED_CATEGORY,
  FEATURED_ICON,
  FEATURED_TITLE,
  HIGHEST_MEALS_PER_DELIVERY_PLAN
} from './constants'
import { createMerchandiseItem, mapMeals } from './utils'
import { getCollections, getMenu } from '../../redux-api-bridge/menu/selectors'
import {
  getDaysWithMenuAvailable,
  getUpcomingDeliveries
} from '../MyDeliveries/selectors'

import { createSelector } from 'reselect'
import {
  getActiveOrder,
  getOrderMealsCountWithStock,
  getNoExtraMealPlanIsCompleted
} from '../../redux-api-bridge/order/selectors'
import { isMobileDevice } from '../../utils/utils'
import moment from 'moment'
import { pathOr } from 'ramda'
import { isEconomicPlanSelector } from 'src/redux-api-bridge/selectors'
import { nextOrderDeliveryDateIndexSelector } from '../Orders/selectors'

export const getStore = state =>
  pathOr(null, ['api', 'subscription', 'info', 'store_id'], state)

export const isMenuAvailable = state =>
  state.menu.menu && state.menu.menu.available

export const getLastOrder = state => state.api.subscription.lastOrder

const MEAT_TYPES = [
  'Beef',
  'Chicken',
  'Other fish',
  'Other meat',
  'Pork',
  'Salmon',
  'Seafood',
  'Vegan',
  'Vegetarian'
]

const NEW_MARKETS = ['MARKET']

export const getCategory = meal => {
  const meatTag =
    meal.specifications_detail &&
    meal.specifications_detail
      .filter(tag => tag && tag.label.trim())
      .find(tag => MEAT_TYPES.indexOf(tag) !== -1)
  const meatLabel =
    meal.meat_type || (typeof meatTag === 'undefined' ? meatTag : null)

  const meats = ['Duck', 'Lamb', 'Other meat', 'Beef', 'Pork']
  const seafood = [
    'Catfish',
    'Cod',
    'Crab',
    'Other fish',
    'Salmon',
    'Scallop',
    'Seafood',
    'Shrimp',
    'Snapper',
    'Sole',
    'Tilapia',
    'Trout',
    'Tuna',
    'fish '
  ]
  const poultry = ['Chicken', 'Turkey']
  const vegan = ['Vegan']
  const vegetarian = ['Vegetarian']

  if (meats.includes(meatLabel)) {
    return 'meat'
  } else if (seafood.includes(meatLabel)) {
    return 'seafood'
  } else if (poultry.includes(meatLabel)) {
    return 'poultry'
  } else if (vegan.includes(meatLabel)) {
    return 'vegan'
  } else if (vegetarian.includes(meatLabel)) {
    return 'vegetarian'
  }

  return null
}

export const getSettings = state =>
  pathOr([], ['api', 'subscription', 'settings'], state)

export const getSelectionPreview = createSelector(getMenu, menu => {
  if (!menu.available) return []
  return menu.categories.map(cat => {
    const mealsFormated = cat.meals.map(meal => mapMeals(meal, cat))
    return {
      id: cat.id,
      name: cat.title,
      title: cat.label,
      subtitle: cat.subtitle,
      tag: cat.tag,
      icon: cat.icon,
      cover_title: cat.cover_title,
      cover_text: cat.cover_text,
      cover_image: isMobileDevice() ? cat.cover_image_mobile : cat.cover_image,
      cover_image_mobile: cat.cover_image_mobile,
      cover_image_macro: cat.cover_image_macro,
      meals: mealsFormated,
      merchandiseMeals:
        cat.merchandised_new &&
        createMerchandiseItem(cat.merchandised_new, mealsFormated)
    }
  })
})

export const getCollectionsPreview = createSelector(
  getCollections,
  collections => {
    if (!collections || collections.length <= 0) return []
    return collections
  }
)

export const getSubscriptionPlan = state => state.api.subscription.userPlan
export const isUpdatingOrder = state => state.api.updateStatus.inFlight

export const getMenuStatus = state => state.menu
export const upcommingOrdersStatus = state => state.api.upcommingOrderStatus

export const getProfilesStatus = state =>
  pathOr(null, ['api', 'profilesStatus'], state)

const getUpcomingOrderStatus = state =>
  pathOr({}, ['api', 'upcommingOrderStatus'], state)

const getSkipOrderStatus = state =>
  pathOr({}, ['api', 'skipOrderStatus'], state)

const getSubscriptionDataStatus = state =>
  pathOr({}, ['api', 'getSubscriptionDataStatus'], state)

const isInitiating = createSelector(
  [getUpcomingOrderStatus, getSkipOrderStatus, getSubscriptionDataStatus],
  (upcomingOrderStatus, skipOrderStatus, subscriptionData) =>
    subscriptionData.inFlight ||
    upcomingOrderStatus.inFlight ||
    !upcomingOrderStatus.succeeded ||
    skipOrderStatus.inFlight
)

export const isLoading = createSelector(
  isInitiating,
  getMenuStatus,
  isUpdatingOrder,
  getProfilesStatus,
  (isInitiating, menuStatus, isUpdatingOrder, profiles) => {
    return (
      isInitiating ||
      menuStatus.inFlight ||
      !menuStatus.succeeded ||
      isUpdatingOrder ||
      profiles.inFlight
    )
  }
)

export const getMealsInDelivery = state =>
  (state.api.subscription.userPlan &&
    +state.api.subscription.userPlan.mealsPerDelivery) ||
  0

export const orderReadyToBeConfirmed = (state, props) => {
  const mealsInDelivery = getMealsInDelivery(state)
  const orderMealsCountWithStock = getOrderMealsCountWithStock(state)
  const noExtraMealPlanIsCompleted = getNoExtraMealPlanIsCompleted(state)

  const currentUrl = window.location.href
  const urlSearchParams = new URLSearchParams(currentUrl.split('?')[1])
  const isAfterCutoff = urlSearchParams.get('afterCutoff') === 'true'
  if (isAfterCutoff) {
    const order = state?.orders?.data[props.date]
    const originalOrderQty = order?.confirmedItems?.reduce((acc, item) => {
      return acc + item.qty
    }, 0)

    return orderMealsCountWithStock === originalOrderQty
  }
  return (
    (orderMealsCountWithStock >= mealsInDelivery &&
      !noExtraMealPlanIsCompleted) ||
    (orderMealsCountWithStock === mealsInDelivery && noExtraMealPlanIsCompleted)
  )
}

export const getErrorMessages = state => state.api.updateStatus.error || null

const calculateStock = (initialCount, currentMeal) =>
  initialCount + (currentMeal.stock < 0 ? 0 : currentMeal.stock)

export const showCollectionsQuickFilter = state => {
  const currentMenu = getSelectionPreview(state)
  const merchandiseMeals = getMerchandiseMealsByDate(state)
  return (
    merchandiseMeals &&
    merchandiseMeals.length > 0 &&
    currentMenu &&
    currentMenu.length > 0
  )
}

export const getCurrentStock = state => {
  const currentMenu = getSelectionPreview(state)
  // get result merchandiseSets in personalized
  const merchandiseMeals = getMerchandiseMealsByDate(state)

  const currentMenuComplete = currentMenu.map(category => {
    const {
      id,
      title,
      name,
      icon,
      cover_title,
      cover_text,
      cover_image
    } = category
    const meals = category.meals || []
    return {
      id,
      title,
      badge: NEW_MARKETS.includes(name) ? 'NEW' : category.tag,
      icon,
      cover_title,
      cover_text,
      cover_image,
      meals: meals.length,
      stock: meals.reduce(calculateStock, 0)
    }
  })

  if (
    merchandiseMeals &&
    merchandiseMeals.length > 0 &&
    currentMenuComplete &&
    currentMenuComplete.length > 0
  ) {
    return [
      // First Tag to MerchandiseExperiment
      currentMenuComplete[0],
      {
        id: FEATURED_CATEGORY,
        title: FEATURED_TITLE,
        badge: null,
        meals: 0,
        stock: 1,
        icon: FEATURED_ICON
      },
      ...currentMenuComplete.slice(1)
    ]
  } else {
    return currentMenuComplete
  }
}

const getSchedulingStatus = state => state.api.schedulingStatus

export const getAddresses = state =>
  pathOr(null, ['api', 'subscription', 'info', 'addresses'], state)

export const getDeliveryDays = state =>
  pathOr([], ['api', 'subscription', 'info', 'deliveryDays'], state)

export const getCurrentDate = createSelector(
  state => state.api.schedulingStatus.newDate,
  date => date
)

export const showUpgradeTooltip = state => {
  //If it is the first visit we dont have this property in db
  //and we want to show the modal
  const subscriptionPlan = getSubscriptionPlan(state)
  const isEconomicPlan = isEconomicPlanSelector(state)
  // we only show modal for meals per delivery plans less than 16 meals
  let showUpdatePlanTooltip = {
    showModal:
      subscriptionPlan?.mealsPerDelivery < HIGHEST_MEALS_PER_DELIVERY_PLAN &&
      !isEconomicPlan,
    exceededPlanCounter: 0,
    exceedsPlan: false
  }
  const setting = getSettings(state).find(
    setting => setting.key === 'showUpgradeTooltip'
  )
  if (state.orders && state.orders.active && setting && setting.value) {
    const value = JSON.parse(setting.value)
    //Show upgrade plan tooltip after 3 months since last close
    const untilDate = moment(new Date(value.sinceDate)).add(3, 'M')
    const data = getActiveOrder(state)
    let mealsInDelivery = 0
    data?.items?.forEach(item => (mealsInDelivery += item.qty))

    showUpdatePlanTooltip.exceedsPlan =
      mealsInDelivery > subscriptionPlan.mealsPerDelivery
    showUpdatePlanTooltip.showModal =
      (value.exceededPlanCounter === 1 || moment().isAfter(untilDate)) &&
      subscriptionPlan.mealsPerDelivery < HIGHEST_MEALS_PER_DELIVERY_PLAN &&
      !isEconomicPlan

    showUpdatePlanTooltip.exceededPlanCounter = value.exceededPlanCounter
  }
  return showUpdatePlanTooltip
}

export const useMenuService = state => {
  const setting = getSettings(state).find(
    setting => setting.key === 'useMenuService'
  )
  return setting && setting.value && setting.value === 'true'
}

export const getSelectedUpcomingDay = date =>
  createSelector(getDaysWithMenuAvailable, days => {
    const day = days.find(day => day.date === date)
    if (!day && days.length) return null
    return day
  })

export const selectedTimeslot = createSelector(
  [getSchedulingStatus],
  schedulingStatus => schedulingStatus.newTimeslot
)

export const isScheduled = createSelector(
  [getSchedulingStatus],
  schedulingStatus => schedulingStatus.scheduled
)

export const cutOff = createSelector(
  [getSchedulingStatus],
  schedulingStatus => schedulingStatus.cutOff
)

export const getAddress = createSelector([getAddresses], addresses => {
  if (addresses && addresses.length) {
    const { street, postcode } = addresses[0]
    const arr = [street, postcode].filter(str => str && str.trim())
    return arr.join(', ')
  }
  return ''
})

export const isLocalUser = state =>
  pathOr(false, ['api', 'subscription', 'info', 'ring', 'is_local'], state)

export const getProfilesInfo = state =>
  pathOr(null, ['api', 'subscription', 'info', 'profiles'], state)

export const getHasPendingSurvey = state =>
  pathOr(null, ['api', 'userSurvey', 'hasPendingSurvey'], state)

export const getHasPendingForYouSurvey = state =>
  pathOr(null, ['api', 'userSurvey', 'hasPendingForYouSurvey'], state)

export const getSurveySucceeded = state =>
  pathOr(false, ['api', 'userSurvey', 'succeeded'], state)

export const getProfiles = createSelector(
  [getProfilesInfo],
  profiles => profiles
)

export const getMerchandiseMealsByDate = createSelector(
  [getCollectionsPreview],
  collections => {
    return collections
  }
)

export const getRing = state =>
  pathOr(null, ['api', 'subscription', 'info', 'ring'], state)

export const getMealsSelector = state =>
  pathOr([], ['menu', 'menu', 'meals'], state)

export const getNextMenuUpcomingDay = createSelector(
  [getUpcomingDeliveries, nextOrderDeliveryDateIndexSelector],
  (upcomingDays, nextOrderIndex) => {
    if (
      nextOrderIndex === undefined ||
      nextOrderIndex === null ||
      nextOrderIndex < 0
    )
      return null

    if (upcomingDays.length >= nextOrderIndex) {
      return upcomingDays[nextOrderIndex]
    }
    return null
  }
)
