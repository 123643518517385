import { createSelector } from 'reselect'
import { mealsAreEquals } from 'src/utils/utils'

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE

export const getMealDetail = createSelector(
  state => state.mealDetail.data,
  meal => {
    if (!meal) {
      return null
    }

    return {
      imageFullUrl: meal.image.full_path,
      chef_img:
        IMG_URL_SOURCE +
        (meal.bannerpic?.includes('/media/avatar')
          ? meal.bannerpic
          : `/media/avatar/${meal.bannerpic}`),
      stars: 0,
      ...meal
    }
  }
)

export const mealNutritionInfo = createSelector(
  state => state.mealDetail.nutritionInfo.data,
  (state, mealId) => mealId,
  (data, mealId) => data[mealId] || null
)

export const mealNutritionInfoLoading = createSelector(
  state => state.mealDetail.nutritionInfo.loading,
  (state, mealId) => mealId,
  (loading, mealId) => loading[mealId] || false
)

export const mealDetailLoading = createSelector(
  state => state.mealDetail.loading,
  loading => loading
)

export const checkIfAvailableForMenuDay = state => {
  if (!state.menu || !state.menu.menu) return false

  const { categories } = state.menu.menu
  const meal = state.mealDetail.data

  const products = categories.reduce(
    (meals, category) => [
      ...meals,
      ...category.meals,
      ...(category.personalized
        ? category.personalized.reduce(
            (meals, profile) => [...meals, ...profile.meals],
            []
          )
        : [])
    ],
    []
  )

  return meal && !!products.find(p => mealsAreEquals(p, meal))
}

export const checkMealInStock = state => {
  if (
    !state.menu ||
    !state.menu.menu ||
    !state.mealDetail ||
    !state.mealDetail.data
  )
    return false

  const { categories } = state.menu.menu
  const meal = state.mealDetail.data

  const products = categories.reduce(
    (meals, category) => [
      ...meals,
      ...category.meals,
      ...(category.personalized
        ? category.personalized.reduce(
            (meals, profile) => [...meals, ...profile.meals],
            []
          )
        : [])
    ],
    []
  )
  const product = products.find(p => mealsAreEquals(p, meal))
  return meal && product && product.inStock
}
