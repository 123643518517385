import { useVariant } from '@unleash/proxy-client-react'
import { useEffect } from 'react'
import { FF_SKU_LINES_TEST } from 'src/constants/feature-flags'
import { trackExperimentViewed } from 'src/utils/tracking'

export function useTrackSKUExperimentViewed() {
  const variant = useVariant(FF_SKU_LINES_TEST.name)

  useEffect(() => {
    if (!variant.enabled) {
      return
    }
    trackExperimentViewed({
      experiment_id: '52421',
      experiment_name: 'Menu - SKU Expansion lines',
      page_name: 'Menu / Product Selection',
      variation_name: variant.name
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant])
}
