import { handleActions } from 'redux-actions'

import * as menuActions from './actions'
import { MENU_VIEW_MODE } from 'src/constants/menu'

const initialState = {
  alreadyRequestedMeals: null,
  menu: null,
  date: null,
  inFlight: false,
  prefetchInFlight: false,
  succeeded: false,
  error: null,
  isCrossSellingModalOpen: false,
  initialScrollTo: null,
  viewMode: MENU_VIEW_MODE.CONDENSED,
  crossSelling: {
    inFlight: false,
    error: false,
    succeeded: false,
    items: null,
    category: null,
    date: null,
    title: '',
    subTitle: ''
  },
  fullMenuMealInitialPosition: {
    headerId: null,
    mealPosition: null
  },
  crossSellingModalSeen: false
}

export default handleActions(
  {
    [menuActions.getUserMenuStart]: (state, { payload: { fromPrefetch } }) => ({
      ...state,
      menu: null,
      inFlight: true,
      succeeded: false,
      error: null,
      prefetchInFlight: fromPrefetch
    }),
    [menuActions.getUserMenuFail]: (state, action) => ({
      ...state,
      inFlight: false,
      prefetchInFlight: false,
      succeeded: false,
      error: action.payload,
      menu: null,
      date: null
    }),
    [menuActions.getUserMenuSuccess]: (state, { payload: { menu, date } }) => ({
      ...state,
      inFlight: false,
      prefetchInFlight: false,
      succeeded: true,
      error: null,
      menu,
      date
    }),
    [menuActions.getUserAlreadyRequestedMealsStart]: state => ({
      ...state,
      alreadyRequestedMeals: {
        inFlight: true,
        succeeded: false,
        error: null,
        meals: [],
        date: null
      }
    }),
    [menuActions.getUserAlreadyRequestedMealsSuccess]: (
      state,
      { payload: { meals, date } }
    ) => ({
      ...state,
      alreadyRequestedMeals: {
        inFlight: false,
        succeeded: true,
        error: null,
        meals,
        date
      }
    }),
    [menuActions.getUserAlreadyRequestedMealsFail]: (state, action) => ({
      ...state,
      alreadyRequestedMeals: {
        inFlight: false,
        succeeded: false,
        error: action.payload,
        meals: [],
        date: null
      }
    }),
    [menuActions.resetUserMenu]: () => initialState,
    [menuActions.setIsCrossSellingModalOpen]: (state, action) => ({
      ...state,
      isCrossSellingModalOpen: action.payload
    }),
    [menuActions.setInitialScrollTo]: (state, action) => ({
      ...state,
      initialScrollTo: action.payload
    }),
    [menuActions.setMenuViewMode]: (state, action) => ({
      ...state,
      viewMode: action.payload
    }),
    [menuActions.getCrossSellingItemsStart]: state => ({
      ...state,
      crossSelling: {
        inFlight: true,
        succeeded: false,
        error: null,
        items: null,
        category: null,
        title: '',
        subTitle: ''
      }
    }),
    [menuActions.getCrossSellingItemsFail]: (state, action) => ({
      ...state,
      crossSelling: {
        inFlight: false,
        succeeded: false,
        error: action.payload,
        items: null,
        category: null,
        title: '',
        subTitle: ''
      }
    }),
    [menuActions.getCrossSellingItemsSuccess]: (
      state,
      { payload: { items, date, category, title, subTitle } }
    ) => ({
      ...state,
      crossSelling: {
        inFlight: false,
        succeeded: true,
        error: null,
        items,
        category,
        date,
        title,
        subTitle
      }
    }),
    [menuActions.setCrossSellingModalSeen]: state => ({
      ...state,
      crossSellingModalSeen: true
    }),
    [menuActions.setFullMenuMealInitialPosition]: (
      state,
      { payload: { headerId, mealPosition } }
    ) => ({
      ...state,
      fullMenuMealInitialPosition: {
        headerId,
        mealPosition
      }
    })
  },
  initialState
)
