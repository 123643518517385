import { useEffect } from 'react'
import { CuiButton } from 'front-lib'
import { useTrackMembershipModal } from '../../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import {
  Step,
  MembershipData,
  MembershipOverlayName,
  MembershipOverlayActions
} from 'src/types/membership'
import UnityPassImage from 'src/assets/img/unitypass.png'
import './UnityPassIntroduction.scss'
import { getDiscountInfo } from '../utils'
import { useLifeSpan } from 'src/api/User/useLifeSpan'
import { useSelector } from 'react-redux'
import { getUserFirstNameSelector } from 'src/components/Header/selectors'

/**
 * Types
 */
interface Props {
  onContinue: (step: Step) => void
  membershipData: MembershipData
}

/**
 * UnityPassIntroduction component
 */

export function UnityPassIntroduction({ onContinue, membershipData }: Props) {
  const { trackModalViewed, trackOptionClicked } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.introduction
  })

  const userName = useSelector(getUserFirstNameSelector)
  const { membership } = membershipData
  const { lifespan } = useLifeSpan()

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { finalCost, fullCost, discountRate } = getDiscountInfo(membership)

  function handleContinue() {
    trackOptionClicked({ overlayAction: MembershipOverlayActions.discover })
    onContinue(MEMBERSHIP_MODAL_STEPS.benefits)
  }

  if (!membership) {
    return null
  }

  return (
    <>
      <div className="unity-pass-banner-with-image">
        <img src={UnityPassImage} alt="Unity Pass" />
        <div className="unity-pass-by-chef">
          {`a special invitation from Chef ${membership?.discount?.source}`}
        </div>
      </div>

      <div className="unity-pass-image-container">
        {membership?.discount?.imageUrl && (
          <img src={membership.discount.imageUrl} alt="placeholder" />
        )}
      </div>

      <div className="introduction-container">
        {lifespan?.wasResurrected ? (
          <>
            <div className="title-container">
              <span className="title">
                Welcome, {userName}! I’m giving you
                <br />
                <strong>{discountRate}% OFF</strong> to try Unity Pass{' '}
                <strong>as an additional to your current discount!</strong>
              </span>
            </div>
            <div className="description-container">
              <span className="description">
                Experience the best of CookUnity with Unity Pass — enjoy free
                delivery, exclusive discounts, and three premium meals per order
                at no extra cost. Only ${finalCost} your first month, then $
                {fullCost}
                /month after.
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="title-container">
              <span className="title">
                Hi, {userName}! I’m unlocking an exclusive offer for you!
                <br />
                <strong>{discountRate}% OFF</strong> with{' '}
                <strong>Chef {membership?.discount?.source}</strong>
              </span>
            </div>
            <div className="description-container">
              <span className="description">
                As a special thank you for being one of my top customers, I’m
                excited to give you {discountRate}% OFF your first month of
                Unity Pass — enjoy free delivery, bigger Add & Save discounts,
                and three premium meals per order at no extra cost. Only $
                {finalCost} your first month, then ${fullCost}/month after.
              </span>
            </div>
          </>
        )}
        <div className="action-buttons">
          <CuiButton onClick={handleContinue}>
            Discover Unity Pass benefits
          </CuiButton>
        </div>
      </div>
    </>
  )
}
