import classnames from 'classnames'
import { CuiIcon } from 'front-lib'

import './SlidingPanel.scss'

const SlidingPanel = props => {
  const {
    isOpen,
    onClose,
    title,
    content,
    actionButtons,
    className,
    style,
    isLoading,
    noAnimation,
    header
  } = props

  return isOpen ? (
    <div
      className={classnames('cookunity__sliding_panel_wrapper', className)}
      style={style}
      onClick={onClose}>
      <div
        className={classnames('cookunity__sliding_panel', { noAnimation })}
        onClick={e => e.stopPropagation()}>
        {header ? (
          <>
            <div className="custom-header">
              {header}
              <CuiIcon
                name="close"
                className="custom-icon-close"
                onClick={onClose}
              />
            </div>
          </>
        ) : (
          <div className="header">
            <p>{title}</p>
            <CuiIcon name="close" className="icon-close" onClick={onClose} />
          </div>
        )}

        <div className={classnames('content', { loading: isLoading })}>
          {!isLoading && content}
        </div>
        {actionButtons && <div className="buttons">{actionButtons}</div>}
      </div>
    </div>
  ) : null
}

export default SlidingPanel
