import './NutritionalFactsSkeleton.scss'

export const NutritionalFactsSkeleton = () => {
  return (
    <section className="nutrition-facts-skeleton">
      <header className="nutrition-facts-skeleton__header">
        <h1 className="nutrition-facts-skeleton__title">Nutrition Facts</h1>
        <div className="skeleton-text skeleton-text--medium"></div>
        <div className="skeleton-text skeleton-text--medium"></div>
      </header>
      <table className="nutrition-facts-skeleton__table nutrition-facts-skeleton__table--grid">
        <tbody>
          {[...Array(12)].map((_, index) => (
            <tr key={index}>
              <th colSpan={3} className="skeleton-text"></th>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="nutrition-facts-skeleton__table nutrition-facts-skeleton__table--grid">
        <tbody>
          {[...Array(4)].map((_, index) => (
            <tr key={index}>
              <th colSpan={3} className="skeleton-text"></th>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="nutrition-facts-skeleton__footer">
        <div className="skeleton-text"></div>
        <div className="skeleton-text"></div>
      </div>
    </section>
  )
}
