import { handleActions } from 'redux-actions'

import * as mealDetail from './actions'

const initialState = {
  data: null,
  loading: false,
  succeed: false,
  failed: false,
  likeMeal: {
    selectedLikeMeal: null,
    inProgress: false
  },
  nutritionInfo: {
    data: {},
    loading: {},
    succeed: {},
    failed: {}
  }
}

export default handleActions(
  {
    [mealDetail.getMealDetail]: state => ({
      ...state,
      data: null,
      loading: true,
      succeed: false,
      failed: false
    }),
    [mealDetail.getMealDetailSuccess]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      succeed: true,
      failed: false
    }),
    [mealDetail.getMealDetailFail]: state => ({
      ...state,
      data: null,
      loading: false,
      succeed: false,
      failed: true
    }),
    [mealDetail.setLikeMeal]: (state, action) => ({
      ...state,
      likeMeal: {
        selectedMealId: action.payload,
        inProgress: true
      }
    }),
    [mealDetail.cleanLikeMeal]: state => ({
      ...state,
      likeMeal: {
        selectedMealId: null,
        inProgress: false
      }
    }),
    [mealDetail.getMealNutritionInfo]: (state, action) => ({
      ...state,
      nutritionInfo: {
        ...state.nutritionInfo,
        loading: {
          ...state.nutritionInfo.loading,
          [action.payload]: true
        },
        succeed: {
          ...state.nutritionInfo.succeed,
          [action.payload]: false
        },
        failed: {
          ...state.nutritionInfo.failed,
          [action.payload]: false
        }
      }
    }),
    [mealDetail.getMealNutritionInfoSuccess]: (state, action) => ({
      ...state,
      nutritionInfo: {
        data: {
          ...state.nutritionInfo.data,
          [action.payload.mealId]: action.payload.labelNutritionalFacts
        },
        loading: {
          ...state.nutritionInfo.loading,
          [action.payload.mealId]: false
        },
        succeed: {
          ...state.nutritionInfo.succeed,
          [action.payload.mealId]: true
        },
        failed: {
          ...state.nutritionInfo.failed,
          [action.payload.mealId]: false
        }
      }
    }),
    [mealDetail.getMealNutritionInfoFail]: (state, action) => ({
      ...state,
      nutritionInfo: {
        ...state.nutritionInfo,
        loading: {
          ...state.nutritionInfo.loading,
          [action.payload]: false
        },
        succeed: {
          ...state.nutritionInfo.succeed,
          [action.payload]: false
        },
        failed: {
          ...state.nutritionInfo.failed,
          [action.payload]: true
        }
      }
    })
  },
  initialState
)
