import moment from 'moment'
import axios from 'axios'
import axiosRetry from 'axios-retry'

import gqlClient from '../../api/gqlClient'

import {
  reactivateSubscription,
  consumeCoupon,
  sampleMenu,
  checkCoupon,
  assignPrepaidOffer,
  generateCartFromResurrectionCampaign
} from './queries'

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

// TODO if you're working with this file, please fix this linter issue and test it with your ticket.
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSampleMenu: () => {
    return gqlClient.publicQuery(sampleMenu, {}).then(res => {
      return res.data.sampleMenu
    })
  },
  reactivateSubscription: () =>
    gqlClient
      .mutation(reactivateSubscription, {
        date: moment().format('YYYY-MM-DD')
      })
      .then(res => res.data),
  consumeCoupon: (coupon, isResurrected = false) =>
    gqlClient
      .mutation(consumeCoupon, {
        coupon,
        isResurrected
      })
      .then(res => res.data),
  applyCouponCode: coupon =>
    gqlClient.publicQuery(checkCoupon, { coupon }).then(res => res.data),
  assignPrepaidOffer: prepaidOfferId =>
    gqlClient
      .mutation(assignPrepaidOffer, {
        prepaidOfferId
      })
      .then(res => res.data),
  generateCartFromResurrectionCampaign: payload =>
    gqlClient
      .mutation(generateCartFromResurrectionCampaign, {
        campaignSlug: payload.campaignSlug,
        chefId: +payload.chefId,
        chefBrandId: +payload.chefBrandId,
        highlightedMealsIds: payload.highlightedMealsIds,
        chefBrandIds: payload.chefBrandIds,
        noPopulateCart: payload.noPopulateCart
      })
      .then(res => res.data)
}
