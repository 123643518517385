import './NutritionalFactsTable.scss'

export const NutritionalFactsTable = ({ mealDetail }) => {
  const getNutrient = nutrientName => {
    const nutrient = mealDetail.labelNutritionalFacts.nutrients.find(
      nut => nut.name === nutrientName
    )
    if (!nutrient) {
      return {
        name: nutrientName,
        unit: '',
        value: 0,
        dailyValue: ''
      }
    }
    return nutrient
  }

  const servingSize = mealDetail?.labelNutritionalFacts?.servingSize || 0
  const servingSizePerContainer =
    mealDetail?.labelNutritionalFacts?.servingsPerPackage || 0

  const calories = getNutrient('calories')
  const totalFat = getNutrient('totalFat')
  const saturatedFat = getNutrient('saturatedFat')
  const transFat = getNutrient('transFat')
  const cholesterol = getNutrient('cholesterol')
  const sodium = getNutrient('sodium')
  const totalCarbohydrate = getNutrient('total_carbohydrate')
  const dietaryFiber = getNutrient('dietary_fiber')
  const totalSugar = getNutrient('total_sugars')
  const addedlSugar = getNutrient('added_sugar')
  const protein = getNutrient('protein')
  const vitaminD = getNutrient('vitamin_d')
  const calcium = getNutrient('calcium')
  const iron = getNutrient('iron')
  const potassium = getNutrient('potassium')

  return (
    <section class="performance-facts">
      <header class="performance-facts__header">
        <h1 class="performance-facts__title">Nutrition Facts</h1>
        <p>Serving Size: {servingSize} </p>
        <p>Serving Per Container: {servingSizePerContainer}</p>
      </header>
      <table class="performance-facts__table">
        <thead>
          <tr>
            <th colspan="3" class="small-info">
              Amount Per Serving
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2">
              <b>Calories: </b>
              {calories.value}
            </th>
            <td></td>
          </tr>
          <tr class="thick-row">
            <td colspan="3" class="small-info">
              <b>% Daily Value*</b>
            </td>
          </tr>
          <tr>
            <th colspan="2">
              <b>Total Fat: </b>
              {totalFat.value || 0} {totalFat.unit}
            </th>
            <td>
              <b>{totalFat.dailyValue}</b>
            </td>
          </tr>
          <tr>
            <td class="blank-cell"></td>
            <th>
              Saturated Fat: {saturatedFat.value || 0} {saturatedFat.unit}
            </th>
            <td>
              <b>{saturatedFat.dailyValue}</b>
            </td>
          </tr>
          <tr>
            <td class="blank-cell"></td>
            <th>
              Trans Fat: {transFat.value} {transFat.unit}
            </th>
            <td>
              <b>{transFat.dailyValue}</b>
            </td>
          </tr>
          <tr>
            <th colspan="2">
              <b>Cholesterol: </b>
              {cholesterol.value} {cholesterol.unit}
            </th>
            <td>
              <b>{cholesterol.dailyValue}</b>
            </td>
          </tr>
          <tr>
            <th colspan="2">
              <b>Sodium: </b>
              {sodium.value} {sodium.unit}
            </th>
            <td>
              <b>{sodium.dailyValue}</b>
            </td>
          </tr>
          <tr>
            <th colspan="2">
              <b>Total Carbohydrate: </b>
              {totalCarbohydrate.value} {totalCarbohydrate.unit}
            </th>
            <td>
              <b>{totalCarbohydrate.dailyValue}</b>
            </td>
          </tr>
          <tr>
            <td class="blank-cell"></td>
            <th>
              Dietary Fiber: {dietaryFiber.value} {dietaryFiber.unit}
            </th>
            <td>
              <b>{dietaryFiber.dailyValue}</b>
            </td>
          </tr>
          <tr>
            <td class="blank-cell"></td>
            <th>
              Total Sugar: {totalSugar.value || 0} {totalSugar.unit}
            </th>
            <td></td>
          </tr>
          <tr>
            <td class="blank-cell"></td>
            <th>
              Added Sugar: {addedlSugar.value || 0} {addedlSugar.unit}
            </th>
            <td></td>
          </tr>
          <tr class="thick-end">
            <th colspan="2">
              <b>Protein: </b>
              {protein.value} {protein.unit}
            </th>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table class="performance-facts__table--grid">
        <tbody>
          <tr>
            <th colspan="2">
              Vitamin D {vitaminD.value} {vitaminD.unit}
            </th>
            <td>{vitaminD.dailyValue}</td>
          </tr>
          <tr>
            <th colspan="2">
              Calcium {calcium.value} {calcium.unit}
            </th>
            <td>{calcium.dailyValue}</td>
          </tr>
          <tr>
            <th colspan="2">
              Iron {iron.value} {iron.unit}
            </th>
            <td>{iron.dailyValue}</td>
          </tr>
          <tr>
            <th colspan="2">
              Potassium {potassium.value} {potassium.unit}
            </th>
            <td>{potassium.dailyValue}</td>
          </tr>
        </tbody>
      </table>

      <p class="small-info">
        * The % Daily Value tells you how much a nutrient in a serving of food
        contributes to a daily diet. 2,000 calories a day is used for general
        nutrition advice.
      </p>
    </section>
  )
}
