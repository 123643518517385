import { put, call, takeEvery, all } from 'redux-saga/effects'

import { GQL } from '../services'
import history from '../history'
import {
  REACTIVATE_SUBSCRIPTION_INIT,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE
} from '../actions/types'
import { toaster } from '../../utils/toaster'
import { pixel } from 'src/utils/pixel'
import { gtm } from 'src/utils/googleTagManager'

function removeEmpty(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value
    }
    return acc
  }, {})
}

function getRedirectUrl(route, params) {
  const cleanParams = removeEmpty(params)
  const query = new URLSearchParams(cleanParams).toString()
  return `${route}?${query}`
}

export function* reactivateSubscription({ payload }) {
  try {
    const subscriptionData = yield call(GQL.reactivateSubscription)

    const urlParams = new URLSearchParams(window.location.search)
    const mealIds = urlParams.get('mealIds')

    let prepaidResponse = {}
    let redirectToDeliveryDate
    let chefIds

    const resurrectionCampaign = {
      id: payload?.resurrectionCampaign?.id,
      chefId: payload?.resurrectionCampaign?.chefId,
      chefBrandId: payload?.resurrectionCampaign?.chefBrandId,
      highlightedMealsIds:
        payload?.resurrectionCampaign?.highlightedMealsIds || [],
      couponCode: payload?.resurrectionCampaign?.couponCode,
      prepaidCampaign: payload?.resurrectionCampaign?.prepaidCampaign,
      chefBrandIds: payload?.resurrectionCampaign?.chefBrandIds
    }

    const couponCode = resurrectionCampaign?.couponCode || payload?.coupon
    const prepaidOffer =
      resurrectionCampaign?.prepaidCampaign || payload?.prepaidOfferId

    const isResurrected = couponCode !== 'RES-35X6'

    if (couponCode) {
      try {
        yield call(GQL.consumeCoupon, couponCode, isResurrected)
      } catch (error) {
        console.error(error)
      }
    }

    if (prepaidOffer) {
      prepaidResponse = yield call(GQL.assignPrepaidOffer, prepaidOffer)
      if (prepaidResponse?.assignPrepaidOffer?.status) {
        toaster.success(
          <span>
            Your prepaid offer was successfully applied to your subscription.
          </span>,
          { autoClose: 5000 }
        )
      } else {
        toaster.error(
          <span>
            There was an error with your prepaid offer. Please contact support.
          </span>,
          { autoClose: 5000 }
        )
      }
    }

    if (resurrectionCampaign.id) {
      try {
        const chefCartResponse = yield call(
          GQL.generateCartFromResurrectionCampaign,
          {
            campaignSlug: resurrectionCampaign.id,
            chefId: resurrectionCampaign.chefId,
            chefBrandId: resurrectionCampaign.chefBrandId,
            chefBrandIds: resurrectionCampaign.chefBrandIds,
            highlightedMealsIds: resurrectionCampaign.highlightedMealsIds,
            noPopulateCart: urlParams.get('npc') !== '0'
          }
        )
        redirectToDeliveryDate =
          chefCartResponse?.generateCartFromResurrectionCampaign?.deliveryDate

        chefIds =
          chefCartResponse?.generateCartFromResurrectionCampaign?.chefIds
      } catch (error) {
        console.error(error)
      }
    }

    toaster.success(
      <span>
        Your subscription was successfully <b>reactivated</b>. See what's new
        and order your next delivery!
      </span>,
      { autoClose: 5000 }
    )

    pixel.trackPageView()
    pixel.trackEvent({
      name: 'Purchase'
    })
    console.log('Pixel events tracked: PageView and Purchase')

    gtm.trackEvent({ name: 'conversion' })

    yield put({
      type: REACTIVATE_SUBSCRIPTION_SUCCESS,
      payload: subscriptionData
    })

    // TODO Find a way to reload subscription data using redux from this saga or remove sagas.
    setTimeout(() => {
      if (redirectToDeliveryDate) {
        const url = getRedirectUrl('/meals', {
          date: redirectToDeliveryDate,
          chefIds,
          mealIds
        })
        history.push(url)
      } else {
        history.push('/validate-cc')
      }
      window.location.reload()
      // Enough time to read the toast message
    }, 5000)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    yield put({
      type: REACTIVATE_SUBSCRIPTION_FAILURE,
      error
    })
  }
}

export default function* rootInitialSaga() {
  yield all([takeEvery(REACTIVATE_SUBSCRIPTION_INIT, reactivateSubscription)])
}
