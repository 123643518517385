import { trackExperimentViewed } from 'src/utils/tracking'

export const isBundle = meal => !!meal.meals

export const trackingProductType = product => {
  const isBundleProduct = isBundle(product)
  if (isBundleProduct) return 'Bundle'
  if (product.category_id === 3 || product.categoryId === 3) return 'Meal'
  return 'Add-on'
}

export const trackExperimentsViewed = ({
  filtersRedesignV2,
  menuEarlyEaters,
  forYouEnabled,
  forYouVariant,
  snacksCategory,
  fullMenuExperiment,
  premiumPhotoVariant,
  hidePremiumMealVariant,
  dollarRetentionVariant,
  showBestPerformerCollection
}) => {
  trackExperimentViewed({
    page_name: 'Menu / Product Selection',
    experiment_name: 'Menu - Advanced Filters Revamp',
    experiment_id: 'mar-5733',
    variation_name: filtersRedesignV2.name
  })

  trackExperimentViewed({
    page_name: 'Menu / Product Selection',
    experiment_name: 'Menu - Early Eaters Sorting',
    experiment_id: 'MAR-5989',
    variation_name: menuEarlyEaters.name
  })

  if (forYouEnabled) {
    trackExperimentViewed({
      page_name: 'Menu / Product Selection',
      experiment_name: 'Menu - For You ML Test',
      experiment_id: 'MAR-5406',
      variation_name: forYouVariant
    })
  }

  trackExperimentViewed({
    page_name: 'Menu / Product Selection',
    experiment_name: 'Marketplace - Menu - Snacks Launch',
    experiment_id: 'mar-6270',
    variation_name: snacksCategory.name
  })

  if (fullMenuExperiment?.enabled) {
    trackExperimentViewed({
      page_name: 'Menu / Product Selection',
      experiment_name: 'Menu - Full Menu Exploration',
      experiment_id: 'MAR-6901',
      variation_name: fullMenuExperiment.name
    })
  }
  trackExperimentViewed({
    page_name: 'Menu / Product Selection',
    experiment_name: 'Menu - Chicago Premium Photos',
    experiment_id: 'MAR-7119',
    variation_name: premiumPhotoVariant.name
  })

  trackExperimentViewed({
    page_name: 'Menu / Product Selection',
    experiment_name: 'Menu - Hide Premium Meals',
    experiment_id: 'MAR-6491',
    variation_name: hidePremiumMealVariant.name
  })

  trackExperimentViewed({
    page_name: 'Menu / Product Selection',
    experiment_name: 'Dollar Retention - Hide Product Lines',
    experiment_id: 'MAR-7279',
    variation_name: dollarRetentionVariant.name
  })

  trackExperimentViewed({
    page_name: 'Menu / Product Selection',
    experiment_name: 'Early Eater Quick Filter',
    experiment_id: 'MAR-8188',
    variation_name: showBestPerformerCollection ? 'treatment-1' : 'control'
  })
}
