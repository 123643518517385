import { CuiModal, CuiBottomSheet } from 'front-lib'
import { useIsMobile } from 'src/hooks/useIsMobile'
import './MealNutritionModal.scss'
import { NutritionalFactsTable } from '../NutritionalFactsTable/NutritionalFactsTable'
import { NutritionalFactsSkeleton } from '../NutritionalFactsTable/NutritionalFactsTableSkeleton'
import { ReactComponent as InfoEmptyCircledIcon } from 'src/assets/svg/info-empty-circled.svg'
import { useEffect } from 'react'
import {
  trackSeeAllNutritionalFactsOverlayClosedViewed,
  trackSeeAllNutritionalFactsOverlayOpenedViewed
} from 'src/utils/tracking'

export const MealNutritionModal = ({
  showNutritionalModal,
  setShowNutritionalModal,
  mealDetail,
  overlayName = null,
  footer = null,
  isLoading = false
}) => {
  const isMobile = useIsMobile()

  useEffect(() => {
    if (showNutritionalModal) {
      trackSeeAllNutritionalFactsOverlayOpenedViewed(overlayName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNutritionalModal])

  const onRequestClosed = () => {
    setShowNutritionalModal(false)
    trackSeeAllNutritionalFactsOverlayClosedViewed(overlayName)
  }

  return !isMobile ? (
    <CuiModal
      className="meal-nutritional-modal"
      onRequestClose={onRequestClosed}
      isOpen={showNutritionalModal}
      aria-label="Meal Nutritional Information">
      <>
        <div className="modal-title">
          <span>{`${mealDetail.name} ${mealDetail.short_description}`}</span>
        </div>
        <div className="meal-nutritional-modal-body">
          {isLoading ? (
            <NutritionalFactsSkeleton />
          ) : (
            <NutritionalFactsTable mealDetail={mealDetail} />
          )}
          <span className="meal-nutritional-info-disclaimer">
            <InfoEmptyCircledIcon
              className="meal-nutritional-info-icon"
              aria-label="Information icon"
            />
            Nutritional info may vary slightly by time of delivery.
          </span>
        </div>
        {footer && <div className="meal-nutritional-info-footer">{footer}</div>}
      </>
    </CuiModal>
  ) : (
    <CuiBottomSheet
      className="meal-nutritional-bottom-sheet"
      isOpen={showNutritionalModal}>
      <CuiBottomSheet.Header onRequestClose={onRequestClosed}>
        <span className="modal-title">{`${mealDetail.name} ${mealDetail.short_description}`}</span>
      </CuiBottomSheet.Header>
      <CuiBottomSheet.Body className="meal-nutritional-modal-sheet-body">
        {isLoading ? (
          <NutritionalFactsSkeleton />
        ) : (
          <NutritionalFactsTable mealDetail={mealDetail} />
        )}
        <span className="meal-nutritional-info-disclaimer">
          <InfoEmptyCircledIcon
            className="meal-nutritional-info-icon"
            aria-label="Information icon"
          />
          Nutritional info may vary slightly by time of delivery.
        </span>
      </CuiBottomSheet.Body>
      {footer && <div className="meal-nutritional-info-footer">{footer}</div>}
    </CuiBottomSheet>
  )
}
