export const FF_ONBOARDING_HAPPY_PATH = 'onboarding-happy-path'
export const FF_ONBOARDING_HAPPY_PATH_PREFERENCES_PAGE =
  'onboarding-happy-path-preferences-page'
export const FF_REFERRAL_PAGE_EXPERIMENT = 'referral-page-experiment'
export const FF_RAFFLE = 'RAFFLE'
export const FF_ONBOARDING_HAPPY_PATH_PREFERENCES_MODAL =
  'ONBOARDING-HAPPY-PATH-PREFERENCES-MODAL'
export const FF_ONBOARDING_SKIP_MODAL = 'ONBOARDING_SKIP_MODAL'
export const FF_MENU_PROMOTIONS = 'PROMOTIONS_IN_MENU'
export const FF_AVALARA_TAXES = 'avalara-taxes'
export const FF_NEW_ORDERS_PAGE = 'new-orders-page'
export const FF_PERSONALIZED_FILTER = 'personalized-filter'
export const FF_NEWNESS_IN_MENU = 'NEWNESS_IN_MENU'
export const FF_MENU_SERVICE_MIGRATE_COLLECTIONS =
  'MENU_SERVICE_MIGRATE_COLLECTIONS'
export const FF_MEALS_ALLERGENS = 'meals-service-allergens'
export const FF_REPLACE_IMAGES_WITH_IMGIX =
  'subscription-front-replace-with-imgix'
export const FF_DELETE_ACCOUNT = 'delete-account'
export const FF_FAST_ORDERING = 'FAST_ORDERING'
export const FF_USE_ALLERGENS = 'meals-service-allergens'

export const FF_PAYMENT_METHOD_CAMPAIGN = 'PAYMENT_METHOD_CAMPAIGN'
export const FF_CART_LOADING_BAR = 'CART_LOADING_BAR'
export const FF_PAUSE_PLAN_REMOVE_ONE_WEEK = 'PAUSE_PLAN_REMOVE_ONE_WEEK'
export const FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT =
  'CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT'

export const FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON =
  'DISABLE_CANCEL_SUBSCRIPTION_BUTTON'

export const FF_MENU_RECIPES = 'MENU_RECIPES'
export const FF_MEAL_DETAIL_VIDEOS = 'MEAL_DETAIL_VIDEOS'
export const FF_CROSS_SELLING_MODAL = 'CROSS_SELLING_MODAL'
export const FF_BANNER_RULES_ENGINE = 'BANNERS-RULES-ENGINE'
export const FF_RESTAURANT_SPECIALS = 'RESTAURANT_SPECIALS'

export const FF_PAUSE_FLOW_ENABLED = 'FF_PAUSE_FLOW_ENABLED'

export const FF_USE_CHEF_SERVICE = 'use-chef-service'
export const FF_GROWTH_WELCOME_BACK_RESURRECTION_MODAL =
  'GROWTH_WELCOME_BACK_RESURRECTION_MODAL'
export const REDIRECT_REACTIVATED_USER_MENU = 'redirect-reactivated-user-menu'

export const FF_SKU_LINES_TEST = {
  name: 'SKU_LINES_TEST',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_PAUSE_INFORMATION_MODAL = 'PAUSE_INFORMATION_MODAL'
export const FF_PARTIAL_CART_EXPERIMENT = {
  name: 'PARTIAL_CART_EXPERIMENT',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_SHOW_REFERRAL_BANNER = 'show_referral_banner'
export const REFERRER_PROMO_SHARE_TRACKER = {
  name: 'referrer_promo_share_component',
  variantsMap: {
    control: 'control',
    treatment: 'treatment'
  }
}

export const FF_USE_FILTERS_REDESIGN_V2 = {
  name: 'USE_FILTERS_REDESIGN_V2',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_USE_FOR_YOU = {
  name: 'USE-FOR-YOU',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1',
    treatment2: 'treatment-2'
  }
}

export const FF_USE_GREEN_EATING = {
  name: 'USE_GREEN_EATING'
}

export const FF_FOR_YOU_MODAL = 'for-you-awareness-modal'
export const FF_TASTE_PROFILE_MODAL = 'taste-profile-awareness-modal'

export const FF_ENABLE_BUY_CREDITS_FEATURE = 'ENABLE_BUY_CREDITS_FEATURE'

export const FF_EATER_PROFILE = 'feature-eater-profile'
export const FF_EATER_PROFILE_UI = 'feature-eater-profile-ui'
export const MEMBERSHIP_MODAL_WHITELIST = 'MEMBERSHIP_MODAL_WHITELIST'

export const FF_MENU_EARLY_EATERS = {
  name: 'MENU_SERVICE_ponderate_early_eaters',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}
export const FF_USE_MEALS_LITE = 'USE_MEALS_LITE'

export const FF_FULL_MENU = {
  name: 'USE_FULL_MENU',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_SHOW_HIDE_PREMIUM_FILTER = {
  name: 'SHOW_HIDE_PREMIUM_FILTER',
  variantsMap: {
    disabled: 'disabled',
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_OFFER_BANNER_FEATURE = 'OFFER_BANNER_FEATURE'
export const FF_ORDER_STATUS = {
  name: 'ORDER_STATUS',
  experiment: {
    id: 'gro-sc-4217',
    name: 'Growth - Activation - Order Status Revolution'
  },
  variantsMap: {
    disabled: 'disabled',
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_CART_IN_ORDERS = 'cart-in-orders'
export const FF_ENABLE_UPDATE_TO_STANDARD_PLAN_FEATURE =
  'ENABLE_UPDATE_TO_STANDARD_PLAN_FEATURE'
export const FF_ENABLE_RETURN_TO_WEEKLY_PLAN_FEATURE =
  'ENABLE_RETURN_TO_WEEKLY_PLAN_FEATURE'

export const FF_SHOW_SNACK_CATEGORY = {
  name: 'SHOW_SNACK_CATEGORY',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_MEAL_CARDS_SWIMLANES = {
  name: 'add-shoppable-swimlanes',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_VIDEO_ONBOARDING = {
  name: 'VIDEO_ONBOARDING',
  experiment: {
    id: 'gro-sc-4449',
    name: 'Growth - Activation - Video Onboarding Test'
  },
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_ENABLE_MOBILE_VALUE_PLAN_MODAL =
  'ENABLE_MOBILE_VALUE_PLAN_MODAL'

export const FF_NEW_HOME_STRUCTURE = 'bff-send-mobile-home-to-web'

export const FF_ENABLE_LTO_COMPONENTS = 'ENABLE_LTO_COMPONENTS'
export const FF_MULTI_ADDRESS = 'MULTI_ADDRESS'

export const FF_USE_PREMIUM_MEAL_PHOTO = {
  name: 'USE_PREMIUM_MEAL_PHOTO',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}
export const FF_CHANGE_PLAN_MODAL = 'CHANGE_PLAN_MODAL'

export const FF_AI_WAITER = {
  name: 'ai-waiter',
  experiment: {
    id: 'ai-waiter',
    name: 'Ai Waiter Test'
  },
  variantsMap: {
    disabled: 'disabled',
    control: 'control',
    treatment1: 'treatment-1'
  }
}
export const EDIT_ORDER_WHITELIST = 'EDIT_ORDER_WHITELIST'

export const FF_SKIP_DEFLECTION_WITH_PROMO = 'SKIP_DEFLECTION_WITH_PROMO'

export const FF_DOLLAR_RETENTION = {
  name: 'MENU_SERVICE_include_dollar_retention_test',
  variantsMap: {
    disabled: 'disabled',
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_RETURN_TO_LAST_REGULAR_FROM_BOX_PLAN_FEATURE =
  'RETURN_TO_LAST_REGULAR_FROM_BOX_PLAN_FEATURE'

export const FF_USE_UNIFIED_CART = 'USE_UNIFIED_CART'
export const FF_DYNAMIC_CHEF_ROW = 'bff-dynamic-chef-row'

export const FF_AUTOPILOT_PANEL = 'reco-panel'

export const FF_RECO_PANEL_AWARENESS_MODAL = 'reco-panel-awareness-modal'

export const FF_PROMOTION_ORGANIC_RESURRECTION =
  'PROMOTION_ORGANIC_RESURRECTION'

export const FF_NUTRITION_INFO = 'USE_NUTRITION_INFO'

export const FF_USE_MACRONUTRIENT_FILTERS = 'USE_MACRONUTRIENT_FILTERS'

export const FF_ENABLE_CTA_IN_CONTEST_MODAL =
  'gem_contest_modal_enable_cta_10182024'

export const FF_ADDRESS_FORM_V2 = 'ADDRESS_FORM_V2'

export const FF_USE_BEST_PERFORMER_COLLECTION = 'USE_BEST_PERFORMER_COLLECTION'
export const FF_NEW_RESURRECTION_FLOW = {
  name: 'NEW_RESURRECTION_FLOW',
  experiment: {
    id: 'new-resurrection-flow',
    name: 'Growth - Activation - New Resurrection Flow'
  },
  variantsMap: {
    disabled: 'disabled',
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_RATING_TAB = {
  name: 'bff-send-rating-tab-to-web',
  variantsMap: {
    disabled: 'disabled',
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_MOBILE_ADOPTION = 'MOBILE_ADOPTION'

export const FF_USE_MENU_FUZZY_SEARCH = 'use_menu_fuzzy_search'
