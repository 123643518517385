import { formatFixedPrice } from '../../utils/utils'
import { ReactComponent as Star } from '../../assets/svg/full-star.svg'
import { ReactComponent as Vegan } from '../../assets/svg/tags/vegan.svg'
import { ReactComponent as Vegetarian } from '../../assets/svg/tags/vegetarian.svg'
import { ReactComponent as Spicy } from '../../assets/svg/tags/spicy.svg'
import { ReactComponent as Strict } from '../../assets/svg/mealcard/Strict.svg'
import { ReactComponent as Info } from '../../assets/svg/info-empty-circled.svg'
import { ReactComponent as DietPescatarian } from '../../assets/svg/tags/pescatarian.svg'
import { ReactComponent as DietPaleo } from '../../assets/svg/tags/paleo.svg'
import { ReactComponent as DietKeto } from '../../assets/svg/tags/keto.svg'
import { ReactComponent as DietMediterranean } from '../../assets/svg/tags/mediterranean.svg'
import { ReactComponent as ClimateFriendly } from '../../assets/svg/tags/climate-friendly.svg'
import { ReactComponent as LowEmissions } from '../../assets/svg/tags/low-emission.svg'
import { MENU_CATEGORIES } from 'src/constants/menu'
import { ReactComponent as PremiumIcon } from '../../assets/svg/mealcard/premium-meals.svg'

export const getAddButtonText = (
  meal,
  planIsCompleted,
  isExtra,
  isFlexUser,
  noExtraMealPlanIsCompleted,
  isBoxOrderModel,
  showPremiumFee
) => {
  const finalPrice = meal.finalPrice || meal.price + (meal.premium_fee || 0)
  switch (true) {
    case isBoxOrderModel:
      return `Add for ${formatFixedPrice(finalPrice)}`
    case noExtraMealPlanIsCompleted && !isExtra:
      return 'Add meal'
    case isFlexUser && isExtra:
      return `Add for ${formatFixedPrice(finalPrice)}`
    case isExtra:
      return `Add extra for ${formatFixedPrice(finalPrice)}`
    case planIsCompleted:
      return `Add meal for ${formatFixedPrice(finalPrice)}`
    case isFlexUser && meal.premium_fee > 0:
      return `Add for ${formatFixedPrice(finalPrice)}`
    case meal.premium_fee > 0 && showPremiumFee:
      return `Add meal + ${formatFixedPrice(meal.premium_fee)}`
    case meal.premium_fee > 0 && !showPremiumFee:
      return 'Add meal'
    case isFlexUser:
      return `Add meal for ${formatFixedPrice(finalPrice)}`
    default:
      return 'Add meal'
  }
}

export const getCondensedPriceText = (
  meal,
  planIsCompleted,
  isExtra,
  isFlexUser,
  noExtraMealPlanIsCompleted,
  isBoxOrderModel
) => {
  const finalPrice = meal.finalPrice || meal.price + (meal.premium_fee || 0)
  switch (true) {
    case isBoxOrderModel:
      return `Add for ${formatFixedPrice(finalPrice)}`
    case noExtraMealPlanIsCompleted && !isExtra:
      return ''
    case isFlexUser && isExtra:
      return `Add for ${formatFixedPrice(finalPrice)}`
    case isExtra:
      return `Add extra for ${formatFixedPrice(finalPrice)}`
    case planIsCompleted:
      return `Add meal for ${formatFixedPrice(finalPrice)}`
    case isFlexUser && meal.premium_fee > 0:
      return `Add for ${formatFixedPrice(finalPrice)}`
    case meal.premium_fee > 0:
      return `Add meal + ${formatFixedPrice(meal.premium_fee)}`
    case isFlexUser:
      return `Add meal for ${formatFixedPrice(finalPrice)}`
    default:
      return ''
  }
}

export const isNew = meal => meal.isNewMeal
export const isNewChef = meal =>
  meal.feature && meal.feature.name.toUpperCase() === 'NEW_AUTHOR'

export const isBackInTheMenu = meal => !!meal.isBackInTheMenu
export const isRestaurantExclusive = meal => !!meal.isMealRestaurantExclusive
export const isMealWithRecipe = meal => !!meal.hasRecipe
export const isUnityPass = meal => !!meal.isUnityPass

export const findSpecificationDetail = (details, tag) =>
  Array.isArray(details) &&
  details.find(
    d =>
      d &&
      typeof d.label === 'string' &&
      d.label.toLowerCase() === tag.toLowerCase()
  )

export const formatMealRating = stars => stars && stars.toFixed(1)

const PROTEIN_TYPES = {
  vegan: 'Vegan',
  vegetarian: 'Vegetarian'
}

const SPECIFICATIONS = {
  spicy: 'spicy',
  low_carbs: 'low carbs',
  low_calories: 'Less Than 600 calories',
  low_sodium: 'Low Sodium'
}

const GREEN_EATING_TYPES = {
  climate_friendly: 'climate_friendly',
  low_emissions: 'low_emissions'
}

const MealTagCategory = {
  NEW: 'NEW',
  PREMIUM: 'PREMIUM',
  PROTEIN_ONLY: 'PROTEIN_ONLY',
  SPICY: 'SPICY',
  RESTRICTION: 'RESTRICTION',
  NOT_MATCH: 'NOT_MATCH',
  CALORIES: 'CALORIES',
  RATING: 'RATING',
  DIET: 'DIET',
  MERCHANDISING: 'MERCHANDISING',
  PROMOTION: 'PROMOTION',
  SPECIFICATION: 'SPECIFICATION'
}

const L1_TAGS = {
  SPICY: {
    label: 'Spicy',
    icon: <Spicy />,
    tooltip: 'Spicy',
    bundleTooltip: 'All products are spicy.',
    color: '#EE5744'
  }
}

const TAG_TYPES = {
  MERCHANDISE: 'Merchandising Labels'
}

export const getCharacteristicsL1 = (meal, max) => {
  if (meal.tagsLevel2) {
    return getCharacteristicsL1FromTags(meal, max)
  }

  const result = []
  if (
    meal.warnings?.diets_not_matching?.length > 0 ||
    meal.warnings?.allergens_not_matching?.length > 0
  ) {
    result.push({
      label: 'Restriction',
      icon: <Strict />,
      tooltip: meal.warning,
      color: '#EE5744'
    })
  } else if (meal.warnings?.restrictions_applied?.length > 0) {
    result.push({
      label: '',
      icon: <Info />,
      tooltip: meal.warning,
      color: '#000'
    })
  }
  if (meal.dietsMatching && meal.dietsMatching.length > 0) {
    meal.dietsMatching.forEach(diet => {
      if (SHOW_DIET_TAG.includes(diet?.name)) {
        result.push(DIET_TAGS[diet.name])
      }
    })
  }
  if (meal.protein_type === PROTEIN_TYPES.vegan) {
    result.push({
      label: meal.protein_type,
      icon: <Vegan />,
      tooltip: 'Vegan',
      bundleTooltip: 'All products are vegan.',
      color: '#199861'
    })
  } else if (meal.protein_type === PROTEIN_TYPES.vegetarian) {
    result.push({
      label: meal.protein_type,
      icon: <Vegetarian />,
      tooltip: 'Vegetarian',
      bundleTooltip: 'All products are vegetarian.',
      color: '#199861'
    })
  }
  if (
    findSpecificationDetail(meal.specifications_detail, SPECIFICATIONS.spicy)
  ) {
    result.push(L1_TAGS.SPICY)
  }

  addGreenEating(meal, result)

  return result?.length > max ? result.splice(0, max) : result
}

const addGreenEating = (meal, result) => {
  if (!meal.filter_by) return
  const isMealClimateFriendly = meal.filter_by.find(
    fb => fb === GREEN_EATING_TYPES.climate_friendly
  )
  const isMealLowEmissions = meal.filter_by.find(
    fb => fb === GREEN_EATING_TYPES.low_emissions
  )
  const isGreenEating = isMealClimateFriendly || isMealLowEmissions

  if (!isGreenEating) return

  if (isMealClimateFriendly && isMealLowEmissions) {
    result.push({
      label: 'Climate Friendly & Low Emissions',
      icon: <ClimateFriendly />,
      tooltip: 'Climate Friendly & Low Emissions',
      bundleTooltip: 'Climate Friendly & Low Emissions',
      color: '#0F6540'
    })
    return
  }

  if (isMealClimateFriendly) {
    result.push({
      label: 'Climate Friendly',
      icon: <ClimateFriendly />,
      tooltip: 'Climate Friendly',
      bundleTooltip: 'Climate Friendly',
      color: '#0F6540'
    })
    return
  }

  if (isMealLowEmissions) {
    result.push({
      label: 'Low Emissions',
      icon: <LowEmissions />,
      tooltip: 'Low Emissions',
      bundleTooltip: 'Low Emissions',
      color: '#0F6540'
    })
    return
  }
}

const getCharacteristicsL1FromTags = (meal, max) => {
  const result = []
  meal.tagsLevel2?.forEach(tag => {
    switch (tag.type) {
      case MealTagCategory.SPICY:
        result.push(L1_TAGS.SPICY)
        break
      case MealTagCategory.RESTRICTION:
        result.push({
          label: 'Restriction',
          icon: <Strict />,
          tooltip: tag.tooltip,
          bundleTooltip: tag.tooltip,
          color: '#EE5744'
        })
        break
      case MealTagCategory.NOT_MATCH:
        result.push({
          label: '',
          icon: <Info />,
          tooltip: tag.tooltip,
          bundleTooltip: tag.tooltip,
          color: '#000'
        })
        break
      case MealTagCategory.DIET:
        const diet = tag.tooltip?.toLowerCase()
        if (DIET_TAGS[diet]) {
          result.push(DIET_TAGS[diet])
        }
        break
      default:
        break
    }
  })
  return result?.length > max ? result.splice(0, max) : result
}

export const getBundleCharacteristicsFromMeals = bundle => {
  const characteristicsFromMeals =
    bundle.meals?.map(meal => getCharacteristicsL1(meal, 3)) || []

  if (
    characteristicsFromMeals.length === 0 ||
    characteristicsFromMeals[0].length === 0
  ) {
    return []
  }

  const result = []
  const firstMealCharacteristics = characteristicsFromMeals[0]

  firstMealCharacteristics.forEach(firstMealCharacteristic => {
    if (
      characteristicsFromMeals.every(characteristics =>
        characteristics.some(c => c.label === firstMealCharacteristic.label)
      )
    ) {
      if (
        !result.find(
          resultCharacteristic =>
            resultCharacteristic.label === firstMealCharacteristic.label
        )
      )
        if (firstMealCharacteristic.label === 'Restriction') {
          // TODO: Workaround. This info should come in menu-service
          firstMealCharacteristic.tooltip = firstMealCharacteristic.tooltip.replace(
            'meal',
            'bundle'
          )
        }
      result.push(firstMealCharacteristic)
    }
  })

  return result.map(c => ({
    ...c,
    tooltip: c.bundleTooltip ?? c.tooltip
  }))
}

const addRating = (meal, result) => {
  if (showRating(meal)) {
    result.push({
      label: formatMealRating(meal.stars),
      icon: <Star />
    })
  }
}

const addSpecification = (meal, result, specification, label) => {
  if (findSpecificationDetail(meal.specifications_detail, specification)) {
    result.push({
      label
    })
  }
}

const addCalories = (meal, result) => {
  if (meal.calories) {
    result.push({
      label: `${meal.calories} cal`
    })
  }
}

export const getRatingCharacteristic = meal => {
  return showRating(meal)
    ? {
        label: formatMealRating(meal.stars),
        icon: <Star />
      }
    : null
}

export const getCharacteristicsL2 = (
  meal,
  max,
  category,
  showRating = true
) => {
  if (meal.characteristics) {
    return getCharacteristicsL2FromTags(meal, max)
  }
  const result = []
  switch (category) {
    case MENU_CATEGORIES.SIDES:
    case MENU_CATEGORIES.BREAKFAST:
    case MENU_CATEGORIES.SAUCES:
    case MENU_CATEGORIES.DRINKS:
      showRating && addRating(meal, result)
      addCalories(meal, result)
      addSpecification(meal, result, SPECIFICATIONS.low_carbs, 'Low carbs')
      addSpecification(meal, result, SPECIFICATIONS.low_sodium, 'Low sodium')
      addSpecification(
        meal,
        result,
        SPECIFICATIONS.low_calories,
        'Low calories'
      )
      break
    case MENU_CATEGORIES.TREATS:
      showRating && addRating(meal, result)
      break
    default:
      showRating && addRating(meal, result)
      addCalories(meal, result)
      addSpecification(meal, result, SPECIFICATIONS.low_carbs, 'Low carbs')
      addSpecification(meal, result, SPECIFICATIONS.low_sodium, 'Low sodium')
      addSpecification(
        meal,
        result,
        SPECIFICATIONS.low_calories,
        'Low calories'
      )
      break
  }
  return result?.length > max ? result.splice(0, max) : result
}

const getCharacteristicsL2FromTags = (meal, max) => {
  const result = []
  meal.characteristics?.forEach(tag => {
    result.push({ label: tag.text })
  })
  return result?.length > max ? result.splice(0, max) : result
}

const showRating = meal =>
  !isNew(meal) && parseInt(meal.reviews) > 0 && parseInt(meal.stars) > 0

export const showProteinOnlyTag = meal =>
  meal.sidesSubCategoryNames?.includes('Protein')

const SHOW_DIET_TAG = [
  'keto diet',
  'paleo',
  'pescatarian diet',
  'mediterranean diet'
]

const DIET_TAGS = {
  'keto diet': {
    label: 'Keto',
    icon: <DietKeto />,
    tooltip: 'Keto',
    bundleTooltip: 'All products are keto friendly.',
    color: '#3939D8'
  },
  keto: {
    label: 'Keto',
    icon: <DietKeto />,
    tooltip: 'Keto',
    bundleTooltip: 'All products are keto friendly.',
    color: '#3939D8'
  },
  paleo: {
    label: 'Paleo',
    icon: <DietPaleo />,
    tooltip: 'Paleo',
    bundleTooltip: 'All products are Paleo friendly.',
    color: '#3939D8'
  },
  'pescatarian diet': {
    label: 'Pescatarian',
    icon: <DietPescatarian />,
    tooltip: 'Pescatarian',
    bundleTooltip: 'All products are Pescatarian friendly.',
    color: '#3939D8'
  },
  pescatarian: {
    label: 'Pescatarian',
    icon: <DietPescatarian />,
    tooltip: 'Pescatarian',
    bundleTooltip: 'All products are Pescatarian friendly.',
    color: '#3939D8'
  },
  'mediterranean diet': {
    label: 'Mediterranean',
    icon: <DietMediterranean />,
    tooltip: 'Mediterranean',
    bundleTooltip: 'All products are Mediterranean friendly.',
    color: '#3939D8'
  },
  mediterranean: {
    label: 'Mediterranean',
    icon: <DietMediterranean />,
    tooltip: 'Mediterranean',
    bundleTooltip: 'All products are Mediterranean friendly.',
    color: '#3939D8'
  }
}

export const getProteinTag = proteinType => {
  if (!proteinType) {
    return
  }

  const proteinsMap = {
    glutenfree: { icon: '', label: 'Gluten Free' },
    keto: { icon: '', label: 'Keto Diet' },
    meat: { icon: '', label: 'Meat' },
    paleo: { icon: '', label: 'Paleo' },
    poultry: { icon: '', label: 'Poultry' },
    seafood: { icon: '', label: 'Seafood' },
    vegan: { icon: 'VG', label: 'Vegan' },
    vegetarian: { icon: 'VE', label: 'Vegetarian' }
  }
  return proteinsMap[proteinType.toLowerCase()]
}

export const MAIN_TAGS = {
  NEW: {
    label: 'NEW',
    color: 'yellow',
    weight: 'bold'
  },
  PREMIUM: {
    label: 'Premium',
    color: 'dark',
    icon: <PremiumIcon />,
    weight: 'bold'
  },
  ITS_BACK: {
    label: "IT'S BACK!",
    color: 'dark',
    weight: 'bold'
  },
  RESTAURANT_EXCLUSIVE: {
    label: 'Restaurant Exclusive',
    color: 'dark',
    weight: 'bold'
  },
  INCLUDES_RECIPE: {
    label: 'Includes Recipe',
    color: 'dark',
    weight: 'bold'
  },
  CLIMATE_FRIENDLY: {
    label: 'Climate Friendly',
    color: 'dark',
    weight: 'bold'
  },
  PROTEIN_ONLY: {
    label: 'Protein Only',
    color: 'dark',
    weight: 'bold'
  },
  UNITY_PASS: {
    label: '<strong>unity</strong>pass',
    color: 'gold',
    weight: 'normal'
  }
}

export const getMainTags = (
  meal,
  max,
  backInTheMenuEnabled,
  recipesEnabled,
  menuPromotion
) => {
  const result = []
  const showIsBackInTheMenu = backInTheMenuEnabled && isBackInTheMenu(meal)
  if (isNew(meal) && !showIsBackInTheMenu) {
    result.push(MAIN_TAGS.NEW)
  }
  if (showIsBackInTheMenu) {
    result.push(MAIN_TAGS.ITS_BACK)
  }
  if (meal.premium_special) {
    result.push(MAIN_TAGS.PREMIUM)
  }
  if (showProteinOnlyTag(meal)) {
    result.push(MAIN_TAGS.PROTEIN_ONLY)
  }
  if (!!menuPromotion) {
    result.push({
      label: menuPromotion.formattedAmount,
      color: 'blue',
      icon: menuPromotion.icon
    })
  }
  if (meal.all_tags) {
    const merchandiseTags = meal.all_tags
      ?.filter(tag => tag.type === TAG_TYPES.MERCHANDISE)
      .filter(
        tag => tag.name !== 'Climate-Friendly' && tag.name !== 'Low Emissions'
      )
    merchandiseTags.forEach(tag => {
      result.push({
        label: tag.name,
        color: 'dark'
      })
    })
  }
  if (recipesEnabled && isMealWithRecipe(meal)) {
    result.push(MAIN_TAGS.INCLUDES_RECIPE)
  }
  if (isRestaurantExclusive(meal)) {
    result.push(MAIN_TAGS.RESTAURANT_EXCLUSIVE)
  }

  if (isUnityPass(meal)) {
    result.push(MAIN_TAGS.UNITY_PASS)
  }

  return result?.length > max ? result.splice(0, max) : result
}
