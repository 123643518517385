import { createAction } from 'redux-actions'

export const getMealDetail = createAction('GET_MEAL_DETAIL_STARTED')
export const getMealDetailSuccess = createAction('GET_MEAL_DETAIL_SUCCEED')
export const getMealDetailFail = createAction('GET_MEAL_DETAIL_FAILED')
export const setLikeMeal = createAction('SET_LIKE_MEAL')
export const cleanLikeMeal = createAction('CLEAN_LIKE_MEAL')
export const getMealNutritionInfo = createAction(
  'GET_MEAL_NUTRITION_INFO_STARTED'
)
export const getMealNutritionInfoSuccess = createAction(
  'GET_MEAL_NUTRITION_INFO_SUCCEED'
)
export const getMealNutritionInfoFail = createAction(
  'GET_MEAL_NUTRITION_INFO_FAILED'
)
