import { useEffect } from 'react'
import { useVariant } from '@unleash/proxy-client-react'
import { trackExperimentViewed as trackExperiment } from 'src/utils/tracking'
import { VariantType } from 'src/types/experiment'

interface Experiment {
  name: string
  experiment: {
    id: string
    name: string
  }
  variantsMap: {
    disabled: string
    control: string
    treatment1: string
  }
}

interface Config {
  trackOnMount?: boolean
}

export function useExperiment(
  experiment: Experiment,
  { trackOnMount }: Config = {}
) {
  const { enabled, name } = useVariant(experiment.name)

  useEffect(() => {
    if (trackOnMount && enabled && name !== VariantType.Disabled) {
      trackExperimentViewed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackOnMount, enabled, name])

  function trackExperimentViewed(extraData = {}) {
    trackExperiment({
      variation_name: name,
      experiment_name: experiment.experiment.name,
      experiment_id: experiment.experiment.id,
      ...extraData
    })
  }

  return {
    // Whether the experiment is ON or OFF
    enabled,
    variant: name,
    // Whether the experiment is on treatment variant or not
    active: enabled && name === experiment.variantsMap.treatment1,
    trackExperimentViewed
  }
}
